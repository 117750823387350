var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-layout-content",
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    { attrs: { title: "Berita Acara Detail Penarikan Unit" } },
                    [
                      _c(
                        "a-row",
                        { attrs: { gutter: [16, 16] } },
                        [
                          _c(
                            "a-col",
                            {
                              attrs: {
                                span: 12,
                                xs: 24,
                                sm: 24,
                                md: 24,
                                lg: 12
                              }
                            },
                            [
                              _c(
                                "a-form",
                                _vm._b(
                                  {
                                    attrs: {
                                      layout: "vertical",
                                      form: _vm.form
                                    }
                                  },
                                  "a-form",
                                  _vm.formItemLayout,
                                  false
                                ),
                                [
                                  _c(
                                    "a-form-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: {
                                        label: "Internal Contract Number:"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.dataForm.internalContractNumber
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-form-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: { label: "Contract Date:" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.dataForm.contractDate) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-form-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: { label: "Customer Number:" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.dataForm.customerNumber) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-form-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: { label: "Customer Name:" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.dataForm.customerName) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-form-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: { label: "Customer Address:" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.dataForm.customerAddress) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: {
                                span: 12,
                                xs: 24,
                                sm: 24,
                                md: 24,
                                lg: 12
                              }
                            },
                            [
                              _c(
                                "a-form",
                                _vm._b(
                                  {
                                    attrs: {
                                      layout: "vertical",
                                      form: _vm.form
                                    }
                                  },
                                  "a-form",
                                  _vm.formItemLayout,
                                  false
                                ),
                                [
                                  _c(
                                    "a-form-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: { label: "Rent Period (Range):" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.dataForm.rentPeriodRange) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-form-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: {
                                        label:
                                          "Rent Period (" + _vm.rentType + "):"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.dataForm.rentPeriod) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-form-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: { label: "Reference Number:" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.dataForm.referenceNumber) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-form-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: { label: "Note:" }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.dataForm.note) + " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-row",
                        { attrs: { gutter: [16, 16] } },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c("TableCustom", {
                                attrs: {
                                  dataSource: _vm.dataSource,
                                  columns: _vm.columnsTable,
                                  paginationcustom: true,
                                  defaultPagination: false,
                                  selectedRowKeys: _vm.selectedRowKeys,
                                  onSelectChange: _vm.onSelectChange,
                                  scroll: { x: "calc(700px + 50%)", y: 400 },
                                  getCheckboxProps: _vm.getCheckboxProps
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 12 } },
                            [
                              _c("Pagination", {
                                attrs: {
                                  total: _vm.totalData,
                                  pageSizeSet: _vm.limit,
                                  idPagination: "pagination1"
                                },
                                on: {
                                  "response-pagesize-change":
                                    _vm.responsePageSizeChange,
                                  "response-currentpage-change":
                                    _vm.responseCurrentPageChange
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 12, align: "end" } },
                            [
                              _c(
                                "a-tag",
                                {
                                  staticStyle: { "font-size": "13px" },
                                  attrs: { color: "grey" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("lbl_total_found")) +
                                      ":" +
                                      _vm._s(_vm.totalData) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 24, align: "end" } },
                            [
                              _c(
                                "a-space",
                                [
                                  _c(
                                    "a-button",
                                    {
                                      attrs: { type: "danger" },
                                      on: { click: _vm.handleBack }
                                    },
                                    [
                                      _c("a-icon", {
                                        staticStyle: { "vertical-align": "0" },
                                        attrs: { type: "left" }
                                      }),
                                      _vm._v(_vm._s(_vm.$t("lbl_back")) + " ")
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        loading: _vm.loadingPrint
                                      },
                                      on: { click: _vm.handlePrint }
                                    },
                                    [
                                      _c("a-icon", {
                                        staticStyle: { "vertical-align": "0" },
                                        attrs: { type: "printer" }
                                      }),
                                      _vm._v("Print ")
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }