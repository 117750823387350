










import WarehouseTransferMutateForm from "@/components/WarehouseTransfer/WarehouseTransferMutateForm.vue";
import { WarehouseTransferMapper } from "@/mapper/WarehouseTransfer.mapper";
import MNotification from "@/mixins/MNotification.vue";
import { CreateWarehouseTransferFormState } from "@/models/interface/warehouse-transfer/CreateWarehouseTransferFormState.interface";
import { RequestWarehouseTransfer } from "@/models/interface/warehouse.interface";
import { logisticServices } from "@/services/logistic.service";
import { Component, Mixins } from "vue-property-decorator";

@Component({
  components: {
    WarehouseTransferMutateForm,
  },
})
export default class UpdateWarehouseTransfer extends Mixins(MNotification) {
  loading = false;

  defaultState: CreateWarehouseTransferFormState =
    new CreateWarehouseTransferFormState();

  get warehouseTransferId(): string {
    return this.$route.params.id as string;
  }

  mounted() {
    this.loading = true;
    logisticServices
      .detailWarehouseTransfer(this.warehouseTransferId)
      .then(res => {
        this.defaultState =
          WarehouseTransferMapper.mapCreateWarehouseTransferFormState(res);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  handleBack(): void {
    this.$router.push({ name: "logistic.warehouse-transfer" });
  }

  handleCreate(formState: CreateWarehouseTransferFormState): void {
    const dto: RequestWarehouseTransfer =
      WarehouseTransferMapper.mapRequestWarehouseTransfer(formState);

    this.loading = true;
    logisticServices
      .submitWarehouseTransfer(this.warehouseTransferId, dto)
      .then(res => {
        this.showNotifSuccess("notif_document_created_as_submit_success", {
          documentNumber: res.documentNumber,
        });
        this.handleBack();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  handleSaveAsDraft(formState: CreateWarehouseTransferFormState): void {
    const dto: RequestWarehouseTransfer =
      WarehouseTransferMapper.mapRequestWarehouseTransfer(formState);

    this.loading = true;
    logisticServices
      .updateWarehouseTransfer(this.warehouseTransferId, dto)
      .then(res => {
        this.showNotifSuccess("notif_update_success", {
          documentNumber: res.documentNumber,
        });
        this.handleBack();
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
