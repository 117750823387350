






































































































import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { LineDtosDetailBastWithdrawl } from "@/models/interface/salesOrder.interface";
import { salesOrderServices } from "@/services/salesorder.service";
import printJS from "print-js";
import Vue from "vue";

interface DataForm {
  internalContractNumber: string;
  contractDate: string;
  customerNumber: string;
  customerName: string;
  customerAddress: string;
  supplierExpeditionAddress: string;
  rentPeriodRange: string;
  rentPeriod: number;
  referenceNumber: string;
  note: string;
}

export default Vue.extend({
  data() {
    return {
      form: this.$form.createForm(this, { name: "viewPrintBAST" }),
      dataSource: [] as LineDtosDetailBastWithdrawl[],
      page: this.page || (1 as number),
      limit: this.limit || (10 as number),
      totalData: 0 as number,
      loadingPrint: false as boolean,
      selectedRowKeys: [] as number[],
      id: "" as string,
      rentType: "Months" as string,
      dataForm: {
        internalContractNumber: "",
        contractDate: "",
        customerNumber: "",
        customerName: "",
        customerAddress: "",
        supplierExpeditionAddress: "",
        rentPeriodRange: "",
        rentPeriod: 0,
        referenceNumber: "",
        note: "",
      } as DataForm,
      columnsTable: [
        {
          title: "No",
          dataIndex: "no",
          key: "no",
          width: 150,
          scopedSlots: { customRender: "no" },
        },
        {
          title: "Part Number",
          dataIndex: "productCode",
          key: "productCode",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Part Name",
          dataIndex: "productName",
          key: "productName",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_serial_number"),
          dataIndex: "serialNumber",
          key: "serialNumber",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Merk",
          dataIndex: "merk",
          key: "merk",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Qty",
          dataIndex: "qty",
          key: "qty",
          width: 150,
          scopedSlots: { customRender: "isQty" },
        },
        {
          title: "BAPU Number",
          dataIndex: "withdrawlNumber",
          key: "withdrawlNumber",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Last Print By",
          dataIndex: "lastPrintBy",
          key: "lastPrintBy",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
      ],
    };
  },
  methods: {
    getCheckboxProps: (record) => ({
      props: {
        disabled: record.withdrawlNumber ? false : true, // Column configuration not to be checked
        name: "withdrawlNumber",
      },
    }),
    getDetailWithdrawBast() {
      const params: RequestQueryParamsModel = {};
      salesOrderServices
        .getDetailBastWithdrawl(params, this.id)
        .then((response) => {
          this.dataForm.internalContractNumber = response.icNumber;
          this.dataForm.contractDate = response.contractDate;
          this.dataForm.customerNumber = response.customerNumber;
          this.dataForm.customerName = response.customerName;
          this.dataForm.customerAddress = response.customerAddress;
          this.dataForm.supplierExpeditionAddress = response.customerAddress;
          this.dataForm.rentPeriodRange = response.rentPeriodRange;
          this.dataForm.rentPeriod = response.rentPeriod;
          this.rentType = response.rentType;
          this.dataForm.referenceNumber = response.referenceNumber;
          this.dataForm.note = response.notes;
          this.dataSource = response.lineDTOS.map((dataMap, index) => {
            return { ...dataMap, key: index, no: index + 1 };
          });
          this.totalData = response.lineDTOS.length;
        });
    },
    onSelectChange(selectedRowKeys): void {
      this.selectedRowKeys = selectedRowKeys;
    },
    handleBack() {
      this.$router.push("/sales/tarik-unit/list");
    },
    handlePrint() {
      if (this.selectedRowKeys.length > 0) {
        const params: RequestQueryParamsModel = {
          page: this.page - 1,
          limit: this.limit,
        };
        this.loadingPrint = true;
        const idForPrint: string[] = [];
        this.dataSource.forEach((dataForeach, index) => {
          if (this.selectedRowKeys.includes(index)) {
            idForPrint.push(dataForeach.id);
          }
        });
        salesOrderServices
          .getPrintReportBapu(params, idForPrint.join(","))
          .then((data) => {
            if (data) {
              const url = window.URL.createObjectURL(new Blob([data]));
              printJS(url);
            }
          })
          .finally(() => (this.loadingPrint = false));
      } else {
        this.$notification.error({
          message: "Error",
          description: "Select data first to print",
        });
      }
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      // this.onSubmit()
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      // this.onSubmit()
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.getDetailWithdrawBast();
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 10 },
        wrapperCol: { span: 12 },
      };
    },
  },
});
